.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__container {
  /* width: 100%; */
}

.react-autosuggest__suggestions-container {
  display: none;
  z-index: 100;

  margin-top: 5px;
}

.react-autosuggest__input,
.react-autosuggest__suggestions-container {
  width: inherit;
}

.react-autosuggest__input--focused ~ .react-autosuggest__suggestions-container {
  opacity: 1;
  display: block;
}
.react-autosuggest__input {
  width: 600px;
  height: 40px;
  padding-left: 25px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #b5297d;
  border-radius: 4px;
}
.react-autosuggest__suggestions-container {
  background-color: white;
  /*   border: 1px solid red; */
  border-radius: 2px;
  box-shadow: 0 3px 20px rgba(89, 105, 129, 0.3), 0 1px 2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(89, 105, 129, 0.1);
  padding: 0.25rem;
  /* width: 650px; */
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  max-height: 350px; /* Set the maximum height to enable scrolling */
  overflow-y: auto;
}

.example_suggest {
  padding: 10px 15px;
  font-size: 14px;
  color: gray;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 650px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1000;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
