.nav-item {
  z-index: 100;
  /* background: pink; */
}
.color-red {
  color: red;
}
.nav-item.color-red {
  color: green;
}
.indication .nav-link.active {
  background-color: #ffc7c7 !important;
}
/* .coloredTab.active > {
  background-color: red;
} */

.nav {
  --bs-nav-link-color: none;
  --bs-nav-link-hover-color: none;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: #343434;
  font-weight: 500;
}
.nav-tabs {
  height: 38px;
  border-bottom: none;
  border-color: none;
}
.checkTab li:nth-child(1) {
  background: red;
}
.indicationDosingRenal li:nth-child(2) {
  background: #ffc7c7;
}
.indicationDosingHepatic li:nth-child(3) {
  background: #ffc7c7;
}
.indicationDosingIndication li:nth-child(1) {
  background: #ffc7c7;
}
.contraindication li:nth-child(1) {
  background: #ffc7c7;
}
.contraindicationBlackBox li:nth-child(2) {
  background: #ffc7c7;
}
.nav-link {
  color: rgba(102, 102, 102, 1);
  font-weight: 600;
  font-size: 15px;
  padding: 0.5rem 1rem;
}

.tab-content > .tab-pane {
  /* max-height: 122px; */
  overflow-y: auto;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 1rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  height: 1rem;
  border-radius: 0.5rem;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: grey;
}

.custom-scrollbar::-ms-overflow-style {
  display: none;
}

/* .color-red {
  background-color: white;
} */
/* .nav-tabs .nav-link.active {
  background-color: white;
} */

.nav-tabs .nav-link.active.color-red {
  background-color: none;
}
/* .nav-tabs .nav-link.active.color-red {

} */

.nav-tabs .nav-link {
  margin-bottom: none;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}

#uncontrolled-tab-example [data-rb-event-key='profile'] .nav-link {
  background-color: yellow !important;
}

.indicationRenal .nav-link.active {
  background-color: #ffc7c7;
}
.indicationHepatic .nav-link.active {
  background-color: #ffc7c7;
}
.contraindication .nav-link.active {
  background-color: #ffc7c7;
}
.contraindicationBlackBox .nav-link.active {
  background-color: #ffc7c7;
}
