.nav-item {
  z-index: 100;
}
.nav {
  --bs-nav-link-color: none;
  --bs-nav-link-hover-color: none;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: #343434;
  font-weight: 500;
}
.nav-tabs {
  height: 38px;
  border-bottom: none;
  border-color: none;
}

.nav-link {
  color: rgba(102, 102, 102, 1);
  font-weight: 600;
  font-size: 15px;
  padding: 0.5rem 1rem;
}

.tab-content > .tab-pane {
  /* max-height: 122px; */
  overflow-y: auto;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 1rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  height: 1rem;
  border-radius: 0.5rem;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: grey;
}

.custom-scrollbar::-ms-overflow-style {
  display: none;
}

/* .color-red {
  background-color: white;
} */
/* .nav-tabs .nav-link.active {
  background-color: white;
} */

.nav-tabs .nav-link.active.color-red {
  background-color: none;
}
/* .nav-tabs .nav-link.active.color-red {

} */

.nav-tabs .nav-link {
  margin-bottom: none;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}
