.custom-datepicker {
    width: 500px; /* Adjust based on the entire width of the viewport */
    height: 40px;
    align-items: center;
    padding-left: 25px;
    border: 1px solid #b5297d;
    padding-bottom: 2px;
    outline: none;
    border-radius: 4px;
    margin-left: 1px;
   
  }
  @media (max-width: 601px) {
    .custom-datepicker {
      width: 240px;
    }
  }
  @media (max-width: 690px) {
    .custom-datepicker {
      width: 350px;
    }
  }
  .react-datepicker__input-container{
    margin-left: -3px;
  }
  .react-datepicker{
    margin-left: 48px;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  .react-datepicker__close-icon {
    margin-right: 10px;
  }
  .react-datepicker__close-icon::after {
    padding: 0px;
    font-size: 14px;
  }
  .apply-button.disabled:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: not-allowed;
    opacity: 0.5;
    /* Add any additional styling as needed */
  }
  
  .react-datepicker__month-container {
    width: 300px;
    font-size: 14px;
  }
  .react-datepicker__close-icon::after{
    background-color: #b5297d;
    vertical-align: baseline;
  }

  .react-datepicker__navigation {
    top:10px;
  }