.ant-image-preview-switch-left,
:where(.css-dev-only-do-not-override-1adbn6x) .ant-image-preview-switch-right {
  z-index: 10000000;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-image-preview-root
  .ant-image-preview-img {
  width: 55%;
}

/* .changeWidth
  :where(.css-dev-only-do-not-override-1adbn6x).ant-image-preview-root
  .ant-image-preview-img {
  width: 25%;
} */
/* .sc-bXTejn .jvyOEA .reactour__helper .reactour__helper--is-open {
  left: 900px;
} */
